import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useTheme } from 'contexts/ThemeContext';

import pluralize from 'utils/pluralize';

import { HelpTooltip, Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const errorState = {
  errorName: false,
  errorOrder: false,
  errorDescription: false
};

export const ModalForm = props => {
  const { config, onConfirm, actionName, onClose } = props;
  const { categories = [], criterias = [], category = {} } = config;
  const { themeColors } = useTheme();
  const getSubCatWarning = item => {
    if (
      !item.parentKey ||
      !item.parentCategory ||
      item.parentKey === category.key
    ) {
      return false;
    }

    return `si vous cliquez sur “créer la catégorie”, la sous-catégorie “${item.name}“ sera détachée de la catégorie “${item.parentCategory.name}“`;
  };
  const getCriteriaWarning = item => {
    if (
      !item.subCategory ||
      !item.parentCategory ||
      item.categoryKey === category.key
    ) {
      return false;
    }

    return `si vous cliquez sur “créer la catégorie”, le critère ${item.key} sera détaché de la catégorie “${item.parentCategory.name}” > “${item.subCategory.name}”`;
  };
  const getVisibilityIcon = item => {
    return item.active ? (
      <VisibilityIcon style={{ color: themeColors.light }} />
    ) : (
      <VisibilityOffIcon style={{ color: themeColors.light }} />
    );
  };
  const parentList = categories
    .filter(f => f.parentKey === null)
    .map(e => ({ name: e.name, key: e.key }));

  const subCatList = categories
    .filter(f => f.parentKey !== null)
    .map(e => ({
      name: e.name,
      key: e.key,
      criterias: `${e.subCriteria.nb} ${pluralize(
        'critère',
        e.subCriteria.nb
      )}`,
      warning: getSubCatWarning(e)
    }));
  const criteriaList = criterias.map(e => ({
    name: e.name,
    key: e.key,
    description: e.description,
    visibility: getVisibilityIcon(e),
    warning: getCriteriaWarning(e)
  }));
  const initialState = {
    name: category.name || '',
    name_en: category.name_en || '',
    key: category.key || '',
    order: category.order || '',
    description: category.description || '',
    visibility: category.active === false ? 'Inactif' : 'Actif',
    selectedParentCategory:
      (category.parentKey &&
        parentList.filter(el => el.key === category.parentKey)) ||
      [],
    selectedSubCategories:
      (category.subCategory &&
        category.subCategory.category &&
        subCatList.filter(el =>
          category.subCategory.category.find(cat => el.key === cat.key)
        )) ||
      [],
    selectedCriterias:
      (category.subCriteria &&
        category.subCriteria.criteria &&
        criteriaList.filter(el =>
          category.subCriteria.criteria.find(cat => el.key === cat.key)
        )) ||
      [],
    isMap: category.isMapDisplay
  };

  const [
    {
      name,
      name_en,
      key,
      order,
      description,
      visibility,
      selectedParentCategory,
      selectedSubCategories,
      selectedCriterias,
      isMap
    },
    setState
  ] = React.useState(initialState);
  const [
    { errorName, errorKey, errorOrder, errorDescription },
    setError
  ] = React.useState(errorState);
  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };
  const toggleErrorKey = state => {
    setError(prevState => ({ ...prevState, errorKey: state }));
  };
  const toggleErrorOrder = state => {
    setError(prevState => ({ ...prevState, errorOrder: state }));
  };
  const toggleErrorDescription = state => {
    setError(prevState => ({ ...prevState, errorDescription: state }));
  };
  const handleChange = field => value => {
    const text = value.trim();
    if (field === 'name') {
      toggleErrorName(!text);
      setState(prevState => ({ ...prevState, name: text }));
    }
    if (field === 'name_en') {
      toggleErrorName(!text);
      setState(prevState => ({ ...prevState, name_en: text }));
    }
    if (field === 'order') {
      toggleErrorOrder(!text);
      setState(prevState => ({ ...prevState, order: text }));
    }
    if (field === 'description') {
      toggleErrorDescription(!text);
      setState(prevState => ({ ...prevState, description: text }));
    }
    if (field === 'key') {
      const exist = categories.find(
        el => el.key === text && el._id !== category._id
      );
      toggleErrorKey(!text || exist);
      setState(prevState => ({ ...prevState, key: text }));
    }
  };

  const handleChangeParentCategory = value => {
    setState(prevState => ({
      ...prevState,
      selectedParentCategory: value.map(e =>
        categories.find(f => f.key === e.key)
      ),
      selectedSubCategories: []
    }));
  };

  const handleChangeSubCategory = value => {
    setState(prevState => ({
      ...prevState,
      selectedParentCategory: [],
      selectedSubCategories: value.map(e =>
        categories.find(f => f.key === e.key)
      ),
      selectedCriterias: []
    }));
  };

  const handleChangeCriteria = value => {
    setState(prevState => ({
      ...prevState,
      selectedCriterias: value.map(e => criterias.find(f => f.key === e.key))
    }));
  };
  const toggleVisibility = value => {
    setState(prevState => ({ ...prevState, visibility: value }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(errorState);
  };

  const onValidate = () => {
    let error = false;
    const datas = {
      name,
      name_en,
      key,
      order,
      description,
      parentCategory: selectedParentCategory[0] || {},
      subCategories: selectedSubCategories.map(e =>
        categories.find(f => f.key === e.key)
      ),
      active: visibility === 'Actif',
      criterias: selectedCriterias.map(e =>
        criterias.find(f => f.key === e.key)
      ),
      isMapDisplay: isMap
    };
    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (
      !key ||
      categories.find(el => el.key === key && el._id !== category._id)
    ) {
      toggleErrorKey(true);
      error = true;
    }
    if (!order) {
      toggleErrorOrder(true);
      error = true;
    }
    if (!description) {
      toggleErrorDescription(true);
      error = true;
    }

    if (!error) {
      onConfirm(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.audience}
              size="medium"
              widthSize="medium"
              onClick={onValidate}
            >
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const getClearIcon = () => {
    return <ClearIcon style={{ color: themeColors.light }} />;
  };

  const getCriteriasSection = () => {
    if (selectedSubCategories.length !== 0) {
      return (
        <Text>
          Vous ne pouvez pas sélectionner de critère lorsqu&apos;une(ou des)
          sous-catégorie(s) sont définie(s).
        </Text>
      );
    }

    if (selectedParentCategory.length === 0) {
      return (
        <Text>
          Vous ne pouvez pas sélectionner de critère lorsqu&apos;aucune
          catégorie parente n&apos;est définie.
        </Text>
      );
    }

    return (
      <ListField
        options={criteriaList}
        searchOptionsKeys={['name', 'key']}
        onChange={handleChangeCriteria}
        values={selectedCriterias}
        actionTxt="Ajouter un critère"
        placeholder="Age"
        deleteAction
        useWarning
        warningColor="insight"
        textFieldOnTop
        deleteIcon={getClearIcon()}
      />
    );
  };
  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom de la catégorie *
                          </Text>
                          <TextField
                            small
                            placeholder="Niveaux de revenus"
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom de la catégorie (anglais)
                          </Text>
                          <TextField
                            small
                            placeholder="Niveaux de revenus"
                            onChange={handleChange('name_en')}
                            value={name_en}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Description *
                          </Text>
                          <TextField
                            small
                            placeholder="Les personnes qui..."
                            onChange={handleChange('description')}
                            value={description}
                            error={errorDescription}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Key *
                          </Text>
                          <TextField
                            small
                            disabled={!!category.key}
                            placeholder="niveauxDeRevenus"
                            onChange={handleChange('key')}
                            value={key}
                            error={errorKey}
                            errorText="Key non saisie ou appartenant à une autre catégorie"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Ordre *
                          </Text>
                          <TextField
                            small
                            placeholder="Par défault, la dernier catégorie affichée."
                            onChange={handleChange('order')}
                            value={order}
                            error={errorOrder}
                            format="numberInt"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Visibilité *</Text>
                          <Select
                            onChange={toggleVisibility}
                            options={['Actif', 'Inactif']}
                            defaultValue="Choisissez la visibilitée"
                            value={visibility}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item justify="flex-start">
                          <Text color="inherit">Catégorie de type carte</Text>
                          <HelpTooltip
                            style={{ padding: '10px' }}
                            title="Si vous cochez la case, une map dynamique représentera vos critères"
                          />
                          <Switch
                            checked={isMap}
                            onChange={event => {
                              setState(prevState => ({
                                ...prevState,
                                isMap: !!event
                              }));
                            }}
                            options={['Non', 'Oui']}
                            defaultColor="warning"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={0}>
          <Item justify="flex-start">
            <FormSection title="Catégorie parente">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {selectedSubCategories.length !== 0 ? (
                      <Text>
                        Vous ne pouvez pas sélectionner de catégorie parente
                        lorsqu&apos;une sous-catégorie est définie.
                      </Text>
                    ) : (
                      <ListField
                        options={parentList}
                        values={selectedParentCategory}
                        searchOptionsKeys={['name', 'key']}
                        onChange={handleChangeParentCategory}
                        actionTxt="Ajouter une catégorie"
                        placeholder="Socio-démo"
                        deleteAction
                        textFieldOnTop
                        selectNbMax={1}
                        deleteIcon={getClearIcon()}
                      />
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={0}>
          <Item justify="flex-start">
            <FormSection title="Sous-catégories">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {selectedParentCategory.length !== 0 ? (
                      <Text>
                        Vous ne pouvez pas sélectionner de sous-catégorie
                        lorsqu&apos;une catégorie parente est définie.
                      </Text>
                    ) : (
                      <ListField
                        options={subCatList}
                        values={selectedSubCategories}
                        searchOptionsKeys={['name', 'key']}
                        onChange={handleChangeSubCategory}
                        actionTxt="Ajouter une sous-catégorie"
                        placeholder="Logement"
                        deleteAction
                        useWarning
                        warningColor="insight"
                        textFieldOnTop
                        deleteIcon={getClearIcon()}
                      />
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={0}>
          <Item justify="flex-start">
            <FormSection title="Critères">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">{getCriteriasSection()}</Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null,
  actionName: null
};
ModalForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  actionName: PropTypes.string
};

export default ModalForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useTheme } from 'contexts/ThemeContext';

import { CUSTOMERS } from 'utils/rest';

import { Alert, Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

import {
  CustomerActionsTypes,
  useCustomer
} from '../../../contexts/customContext';

const UpdateChildCustomer = props => {
  const [{ customers }, dispatch] = useCustomer();
  const defaultContributions = { default: 0, max: 0 };
  const { themeColors } = useTheme();
  const { onClose, selectedSubAccounts } = props;

  const [financialContribution, setFinancialContribution] = useState([]);
  const [defaultMargin, setDefaultMargin] = useState(0);
  const [maxMargin, setMaxMargin] = useState(0);
  const [contribution, setContribution] = useState(defaultContributions);

  useEffect(() => {
    const fetchData = async () => {
      const customer = selectedSubAccounts[0];
      if (customer && customer.id) {
        setFinancialContribution(customer.financialContribution);
        setDefaultMargin(customer.margin?.rate);
        setMaxMargin(customer.margin?.maxAmount);
        setContribution(customer?.contribution || defaultContributions);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [selectedSubAccounts]);

  const handleEditSubAccount = async () => {
    const data = {
      financialContribution,
      contribution,
      margin: {
        rate: Number(defaultMargin),
        maxAmount: Number(maxMargin)
      }
    };

    // TODO a mettre en bulk update plutot que promise.all (en cas de PML avec +10subAccount)
    const promises = selectedSubAccounts.map(r =>
      CUSTOMERS.updateCostumers(r.id, data)
    );

    await Promise.all(promises);

    const newCustomers = customers.map(customer => {
      const index = selectedSubAccounts.findIndex(r => r.id === customer._id);
      if (index !== -1) {
        return {
          ...customer,
          financialContribution,
          contribution,
          margin: {
            rate: Number(defaultMargin),
            maxAmount: Number(maxMargin)
          }
        };
      }
      return customer;
    });

    dispatch({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        customers: newCustomers
      }
    });

    onClose();
  };

  return (
    <Modal
      {...props}
      onClose={() => {
        onClose();
      }}
    >
      <Container>
        <Row>
          <Item flex style={{ 'flex-direction': 'column' }}>
            <Text fontSize="20px" fontWeight={700}>
              Paramètrage des sous-compte
            </Text>
            <Text fontSize="20px" fontWeight={700}>
              {selectedSubAccounts
                .reduce(
                  (acc, value) =>
                    value?.info?.name ? acc.concat(value.info.name) : acc,
                  []
                )
                .join(', ')}
            </Text>
          </Item>
        </Row>
        {selectedSubAccounts.length > 0 ? (
          <>
            <Row spacing={1}>
              <Item justify="flex-start">
                <FormSection
                  title="Abondement"
                  tooltip="L'abondement s'applique uniquement sur les campagnes prêtes à l'emploi"
                >
                  <Container>
                    <Row spacing={2} justify="flex-start">
                      <Item xs={6} justify="flex-start">
                        <TextField
                          title="Abondement par défaut"
                          small
                          value={(contribution.default || 0) * 100}
                          format="numberDec"
                          unit="%"
                          onChange={e => {
                            const value = e ? parseInt(e, 10) / 100 : e;
                            setContribution({
                              ...contribution,
                              default: value
                            });
                          }}
                        />
                      </Item>
                      <Item xs={6} justify="flex-start">
                        <TextField
                          title="Maximum"
                          small
                          value={contribution.max}
                          format="numberDec"
                          unit="€"
                          onChange={e =>
                            setContribution({ ...contribution, max: e })
                          }
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
                <FormSection title="Prélèvement">
                  <Container>
                    <Row spacing={2} justify="flex-start">
                      <Item xs={6} justify="flex-start">
                        <Text>Prélévement par défaut</Text>
                        <TextField
                          small
                          value={(defaultMargin || 0) * 100}
                          format="numberDec"
                          unit="%"
                          onChange={e => {
                            const value = e ? parseInt(e, 10) / 100 : e;
                            setDefaultMargin(value);
                          }}
                        />
                      </Item>
                      <Item xs={6} justify="flex-start">
                        <Text>Maximum</Text>
                        <TextField
                          small
                          value={maxMargin}
                          format="numberDec"
                          unit="€"
                          onChange={e => setMaxMargin(e)}
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row spacing={1} justify="space-between">
              <Button
                color={themeColors.themeV2.detail}
                variant="contained"
                startIcon={<KeyboardArrowLeftIcon sx={{ color: 'black' }} />}
                onClick={onClose}
              >
                <Text fontSize="14px" fontWeight={600} color="black">
                  Annuler
                </Text>
              </Button>
              <Button
                color={themeColors.backoffice}
                variant="contained"
                onClick={handleEditSubAccount}
                endIcon={<KeyboardArrowRightIcon />}
              >
                <Text fontSize="14px" fontWeight={600} color="inherit">
                  Appliquer
                </Text>
              </Button>
            </Row>
          </>
        ) : (
          <Row>
            <Item>
              <Alert
                severity="warning"
                text="Aucun compte enfant n'est disponible"
              />
            </Item>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

UpdateChildCustomer.defaultProps = {
  onClose: () => null
};
UpdateChildCustomer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default UpdateChildCustomer;
